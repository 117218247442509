import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { ButtonLoading, ButtonStyled, PrimaryButton } from '../../../../../theme/buttons';

export const VerifyResourceOptionMenu = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPostResource, GenericGetResource, } = GenericPromises();
    const [loadingPost, setLoadingPost] = useState(false);
    const [completeVerify, setCompleteVerify] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [resourcesInserted, setResourcesInserted] = useState<{ Recurso: string, Status: string }[]>([])
    const { showSnackBar, SnackbarComponent } = useSnackBar();


    const VerifyResourceOptionMenu = () => {
        setLoadingPost(true);
        let myData = { option_name: props.mySelectedMenu.option_name }
        GenericPostResource(`/optionmenu/verifyresources`, myData)
            .then((response) => {
                setResourcesInserted(response.data.data);
                setCompleteVerify(true);
            }).catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, "error");
            });
    }

    const reloadPage = () => {
        props.setDataMenuLoaded(false);
        GenericGetResource(props.myEnumValue?.route ?? "")
            .then((response) => {
                onCancel();
                props.setObjectCopyOptionMenu(undefined);
                props.setDefaultOptionMenuData(response.data);
                if (response.data && response.data.children && response.data.children.length > 0) {
                    let children = response.data.children;
                    if (children) {
                        children = props.orderMenu(children);
                    }
                    children = children.sort((a: any, b: any) => a.menu_order - b.menu_order);
                    props.setMenuData(children);
                    props.setDataMenuLoaded(true);
                }
                else {
                    props.setMenuData([]);
                    props.setDataMenuLoaded(true);
                }
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, "error");
            });
    }

    const onCancel = () => {
        props.setOpenDialog(false);
    }

    useEffect(() => {
        setDataLoaded(true);
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
                    <div className="w-100">
                        <div className="w-100">
                            {!completeVerify ?
                                <>
                                    <Typography>{t("optionsmenu.dialog.verify_resources")}</Typography>
                                </>
                                :
                                <>
                                    {(resourcesInserted.length === 0) ?
                                        <Typography>{t("optionsmenu.info.resources_same")}</Typography>
                                        :
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            {resourcesInserted.map((value) => (
                                                <ListItem
                                                    key={value.Recurso}
                                                    disableGutters
                                                    secondaryAction={
                                                        value.Status
                                                    }
                                                >
                                                    <ListItemText primary={`${value.Recurso}`} />
                                                </ListItem>
                                            ))}
                                        </List>}
                                </>
                            }
                        </div>
                        <Box className='d-flex flex-row justify-content-end' sx={{ minWidth: 1000 }}>
                            {!completeVerify ?
                                <>
                                    <ButtonStyled variant='contained' className='m-1' onClick={onCancel} disabled={loadingPost}>{t("generic.buttons.cancel")}</ButtonStyled>
                                    <ButtonLoading onClick={() => { VerifyResourceOptionMenu() }} variant='outlined' className='m-1' disabled={loadingPost} isLoading={loadingPost}>{t("generic.buttons.accept")}</ButtonLoading>
                                </>
                                :
                                <PrimaryButton onClick={() => { reloadPage() }} variant='outlined' className='m-1' disabled={!completeVerify}>{t("optionsmenu.actions.reload")}</PrimaryButton>
                            }
                        </Box >
                    </div>
                </Box >
            }
            <SnackbarComponent />
        </>
    )
}
