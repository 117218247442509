import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { GridActionsCellItem, GridColDef, GridEventListener, GridRenderCellParams, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useTranslation } from 'react-i18next';
import { SalesInvoice, SalesInvoiceDetail } from '../../../interfaces/Sales/Invoices/salesinvoices';
import GenericPromises from '../../../api/GenericPromises';
import { useLocation } from 'react-router-dom';
import { UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Autocomplete, Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import { Tax } from '../../../interfaces/Sales/Catalogs/taxes';
import { Retention } from '../../../interfaces/Sales/Catalogs/retentions';
import { PrimaryButton } from '../../../theme/buttons';
import TableSalesInvoiceDetail from '../../../components/Tables/TableSalesInvoiceDetail';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import { TariffFraction } from '../../../interfaces/Sales/Catalogs/tariffFractions';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { User } from '../../../interfaces/Security/users';
import { Menuitem } from '../../../interfaces/Security/menu';

type TariffFractionsComboProps = {
  myTariffFraction: TariffFraction | undefined,
  setMyTariffFraction: React.Dispatch<React.SetStateAction<any>>,
}

const TariffFractionsCombo = ({ myTariffFraction, setMyTariffFraction }: TariffFractionsComboProps) => {
  const [comboTariffFractions, setComboTariffFractions] = useState<TariffFraction[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [t] = useTranslation("global");

  useEffect(() => {
    GenericGetResource("/tarifffractions")
      .then((response) => {
        setComboTariffFractions(response.data.data);
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    setMyTariffFraction(value);
  }

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Autocomplete
          value={myTariffFraction || null}
          size="small"
          sx={{ flex: 1, paddingRight: 1 }}
          options={comboTariffFractions}
          getOptionLabel={(option) => `${option.tariff_fraction_code} - ${option.tariff_fraction_general_description} - ${option.tariff_fraction_specific_description}`}
          defaultValue={undefined}
          renderOption={(props, option: TariffFraction) => (
            <div key={option.tariff_fraction_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.tariff_fraction_code} - {option.tariff_fraction_general_description} - {option.tariff_fraction_specific_description}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.tariff_fraction_id === value.tariff_fraction_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("tarifffractions.title-view")}`}
              variant="filled"
            />
          )}
        />
      }
    </>
  );
}

type TaxComboProps = {
  myTax: Tax | undefined,
  setMyTax: React.Dispatch<React.SetStateAction<any>>,
}

const TaxCombo = ({ myTax, setMyTax }: TaxComboProps) => {
  const [comboTaxes, setComboTaxes] = useState<Tax[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [t] = useTranslation("global");

  useEffect(() => {
    GenericGetResource("/taxes")
      .then((response) => {
        setComboTaxes(response.data.data);
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    setMyTax(value);
  }

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Autocomplete
          value={myTax || null}
          size="small"
          sx={{ flex: 1, paddingRight: 1 }}
          options={comboTaxes}
          getOptionLabel={(option) => `${option.tax_name} - ${option.tax_percent}`}
          defaultValue={undefined}
          renderOption={(props, option: Tax) => (
            <div key={option.tax_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.tax_name} - {option.tax_percent}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.tax_id === value.tax_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("taxes.title-view")}`}
              variant="filled"
            />
          )}
        />
      }
    </>
  );
}

type RetentionComboProps = {
  myRetention: Retention | undefined,
  setMyRetention: React.Dispatch<React.SetStateAction<any>>,
}

const RetentionCombo = ({ myRetention, setMyRetention }: RetentionComboProps) => {
  const [comboRetentions, setComboRetentions] = useState<Retention[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [t] = useTranslation("global");

  useEffect(() => {
    GenericGetResource("/retentions")
      .then((response) => {
        setComboRetentions(response.data.data);
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    setMyRetention(value);
  }

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Autocomplete
          value={myRetention || null}
          size="small"
          sx={{ flex: 1, paddingRight: 1 }}
          options={comboRetentions}
          getOptionLabel={(option) => `${option.retention_name} - ${option.retention_percent}`}
          defaultValue={undefined}
          renderOption={(props, option: Retention) => (
            <div key={option.retention_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.retention_name} - {option.retention_percent}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.retention_id === value.retention_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("retentions.title-view")}`}
              variant="filled"
            />
          )}
        />
      }
    </>
  );
}

type InvoiceBodyProps = {
  salesInvoicePayload?: SalesInvoice | undefined;
  setValue: UseFormSetValue<SalesInvoice>;
  getValues: UseFormGetValues<SalesInvoice>;
  setIsChangedTotalValue: React.Dispatch<React.SetStateAction<boolean>>;
  resourceSalesInvoiceDetails: Menuitem | undefined,
  isAccounted: boolean,
  reset: UseFormReset<SalesInvoice>,
  onPut: (data: SalesInvoice) => void,
  includesForeignTradeComplement: boolean,
  myLocalInvoiceDocType: number,
  rows: SalesInvoiceDetail[],
  loadingPut: boolean,
  setRows: Dispatch<SetStateAction<SalesInvoiceDetail[]>>
}

export const InvoiceBody = ({
  salesInvoicePayload,
  setValue,
  setIsChangedTotalValue,
  isAccounted,
  resourceSalesInvoiceDetails,
  includesForeignTradeComplement,
  myLocalInvoiceDocType,
  rows,
  loadingPut,
  setRows
}: InvoiceBodyProps) => {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [t] = useTranslation("global");
  const { GenericGetResource, GenericPutResource, GenericDeleteResource } = GenericPromises();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openTaxDialog, setOpenTaxDialog] = useState(false);
  const [openRetentionDialog, setOpenRetentionDialog] = useState(false);
  const [openTariffFractionDialog, setOpenTariffFractionDialog] = useState(false);
  const [isCanceledRow, setIsCanceledRow] = useState(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId>(0);
  const [mySelectedRow, setMySelectedRow] = useState<GridRowModel>();
  const [myTempTax, setMyTempTax] = useState<Tax>();
  const [myTempRetention, setMyTempRetention] = useState<Retention>();
  const [myTempTariffFraction, setMyTempTariffFraction] = useState<TariffFraction>();
  const location = useLocation();


  let columns: GridColDef[] =
    [
      {
        field: 'item_code',
        headerName: t("items.fields.item_code"),
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'tariff_fraction_id',
        headerName: t("tarifffractions.title-view"),
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <Tooltip title={`${params.row.tariff_fraction_code ?? ''} - ${params.row.tariff_fraction_specific_description ?? ''} - ${params.row.tariff_fraction_general_description ?? ''}`}>
            <span>
              {(resourceSalesInvoiceDetails?.update) &&
                <IconButton
                  onClick={() => {
                    let myTariffFraction: TariffFraction = {
                      tariff_fraction_id: params.row.tariff_fraction_id,
                      tariff_fraction_code: params.row.tariff_fraction_code,
                      tariff_fraction_general_description: params.row.tariff_fraction_general_description,
                      tariff_fraction_specific_description: params.row.tariff_fraction_specific_description,
                      tariff_fraction_unit_code: params.row.tariff_fraction_unit_code,
                      tariff_fraction_unit_symbol: params.row.tariff_fraction_unit_symbol,
                    }
                    setMyTempTariffFraction(myTariffFraction.tariff_fraction_code ? myTariffFraction : undefined);
                    setMySelectedRow(params);
                    setOpenTariffFractionDialog(true);
                  }}
                  size="small">
                  <EditIcon />
                </IconButton>
              }
              {params.row.tariff_fraction_code} - {params.row.tariff_fraction_specific_description}
            </span>
          </Tooltip>
        ),
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'item_description',
        headerName: t("salesinvoicedetails.fields.concept"),
        flex: 1,
        editable: true,
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <Tooltip title={`${params.row.item_description}`}>
            {params.row.item_description}
          </Tooltip>
        ),
        cellClassName: "my-custom-cell"
      },
      {
        field: 'quantity',
        headerName: t("salesinvoicedetails.fields.quantity"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        flex: 1,
        valueGetter: (value: any) => {
          return roundToTwoDecimals(value.value)
        },
        cellClassName: "my-custom-cell"
      },
      {
        field: 'price_by_unit',
        headerName: t("salesinvoicedetails.fields.price"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'unit_code',
        headerName: t("salesinvoicedetails.fields.unit_code"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'tax_id',
        headerName: t("taxes.title-view"),
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <div>
            {(resourceSalesInvoiceDetails?.update) &&
              <IconButton
                onClick={() => {
                  let myTax: Tax = {
                    tax_id: params.row.tax_id,
                    tax_code: params.row.tax_code,
                    tax_name: params.row.tax_name,
                    tax_percent: params.row.tax_percent,
                  }
                  setMyTempTax(myTax.tax_percent ? myTax : undefined);
                  setMySelectedRow(params);
                  setOpenTaxDialog(true);
                }}
                size="small">
                <EditIcon />
              </IconButton>
            }
            %{params.row.tax_percent} - {params.row.tax_name}
          </div>
        ),
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'retention_id',
        headerName: t("retentions.title-view"),
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <div>
            {(resourceSalesInvoiceDetails?.update) &&
              <IconButton
                onClick={() => {
                  let myRetention: Retention = {
                    retention_id: params.row.retention_id,
                    retention_code: params.row.retention_code,
                    retention_name: params.row.retention_name,
                    retention_percent: params.row.retention_percent,
                  }
                  setMyTempRetention(myRetention.retention_percent ? myRetention : undefined);
                  setMySelectedRow(params);
                  setOpenRetentionDialog(true);
                }}
                size="small">
                <EditIcon />
              </IconButton>
            }
            {params.row.retention_percent}% - {params.row.retention_name}
          </div>
        ),
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'discount',
        headerName: t("salesinvoicedetails.fields.discount"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'total_amount',
        headerName: t("salesinvoicedetails.fields.subtotal_amount"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: t("generic.grid-table.options"),
        cellClassName: 'actions my-custom-cell',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={(resourceSalesInvoiceDetails?.update) ? false : true}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
              disabled={(resourceSalesInvoiceDetails?.delete) ? false : true}
            />,
          ];
        },
        flex: 1
      },
    ];


  const [myColumns, setMyColumns] = useState<GridColDef[]>(columns);

  const reorderColumns = (originalColumns: GridColDef[], newOrder: string[]) => {
    const orderedColumns: GridColDef[] = [];
    const actionsColumn = originalColumns.find(col => col.field === 'actions');
    const columnsToAdd = originalColumns.filter(col => !newOrder.includes(col.field));

    columnsToAdd.forEach(col => {
      if (col.field !== 'actions') {
        orderedColumns.push(col);
      }
    });

    newOrder.forEach((fieldName) => {
      const column = originalColumns.find((col) => col.field === fieldName);
      if (column && column.field !== 'actions') {
        orderedColumns.push(column);
      }
    });

    if (actionsColumn) {
      orderedColumns.push(actionsColumn);
    }

    return orderedColumns;
  };

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-salesinvoicedetails-columns");
    if (myOrderColumns !== null) {
      let myJson: any[] = JSON.parse(myOrderColumns);

      myJson.forEach((columnConfig) => {
        if (columnConfig.type === 'date') {
          columnConfig.headerName = t(`generic.${columnConfig.field}`);
          columnConfig.valueGetter = function (params: GridValueGetterParams<User, any, GridTreeNodeWithRender>) {
            return new Date(params.value);
          };
        } else {
          columnConfig.headerName = t(`salesinvoicedetails.fields.${columnConfig.field}`);
        }
      });

      const reorderedColumns = reorderColumns(columns, myJson.map((col) => col.field));

      setMyColumns((prev) => reorderedColumns);
    } else {
      setMyColumns(columns)
    }
  };


  const onAcceptNewTax = () => {
    let myRow = mySelectedRow?.row;
    myRow.tax_id = myTempTax?.tax_id ?? null;
    myRow.tax_name = myTempTax?.tax_name ?? null;
    myRow.tax_percent = myTempTax?.tax_percent ?? null;
    myRow.tax_code = myTempTax?.tax_code ?? null;
    processRowUpdate(myRow, myRow);
    setOpenTaxDialog(false);
  }

  const onAcceptNewRetention = () => {
    let myRow = mySelectedRow?.row;
    myRow.retention_id = myTempRetention?.retention_id ?? null;
    myRow.retention_code = myTempRetention?.retention_code ?? null;
    myRow.retention_name = myTempRetention?.retention_name ?? null;
    myRow.retention_percent = myTempRetention?.retention_percent ?? null;
    processRowUpdate(myRow, myRow);
    setOpenRetentionDialog(false);
  }

  const onAcceptNewTariffFraction = () => {
    let myRow = mySelectedRow?.row;
    myRow.tariff_fraction_id = myTempTariffFraction?.tariff_fraction_id ?? null;
    myRow.tariff_fraction_code = myTempTariffFraction?.tariff_fraction_code ?? null;
    myRow.tariff_fraction_general_description = myTempTariffFraction?.tariff_fraction_general_description ?? null;
    myRow.tariff_fraction_specific_description = myTempTariffFraction?.tariff_fraction_specific_description ?? null;
    myRow.tariff_fraction_unit_id = myTempTariffFraction?.unit_id ?? null;
    myRow.tariff_fraction_unit_code = myTempTariffFraction?.tariff_fraction_unit_code ?? null;
    myRow.tariff_fraction_unit_symbol = myTempTariffFraction?.tariff_fraction_international_unit_symbol ?? null;
    processRowUpdate(myRow, myRow);
    setOpenTariffFractionDialog(false);
  }

  const changeTotalValue = () => {
    GenericGetResource(`/salesinvoices/${salesInvoicePayload?.sales_invoice_id}`)
      .then((response) => {
        setValue("tax_amount", response.data.tax_amount);
        setValue("retention_amount", response.data.retention_amount);
        setValue("subtotal_amount", response.data.subtotal_amount);
        setValue("discount_amount", response.data.discount_amount);
        setValue("total_amount", response.data.total_amount);
        setIsChangedTotalValue((prev) => !prev);
      })
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (sales_invoice_detail_id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [sales_invoice_detail_id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (sales_invoice_detail_id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [sales_invoice_detail_id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (sales_invoice_detail_id: GridRowId) => () => {
    setIdToDelete((prev) => sales_invoice_detail_id);
    setOpenDeleteDialog(true);
  };

  function roundToTwoDecimals(num: number): number {
    return Math.round(num * 100) / 100;
  }

  const onDeleteRow = () => {
    GenericDeleteResource(`/salesinvoicedetails/${idToDelete}`)
      .then(() => {
        setRows(rows.filter((row) => row.sales_invoice_detail_id !== idToDelete));
        changeTotalValue();
        setOpenDeleteDialog(false);
        showSnackBar(t("salesinvoicedetails.snackbar.delete"), "success")
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }

  const handleCancelClick = (sales_invoice_detail_id: GridRowId) => () => {
    setIsCanceledRow(true);
    setRowModesModel({ ...rowModesModel, [sales_invoice_detail_id]: { mode: GridRowModes.View } });
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const updatedRow: SalesInvoiceDetail = { ...newRow };
    if ((myLocalInvoiceDocType !== 2) && (
      ((updatedRow.quantity ?? 0) <= 0) ||
      (updatedRow.price_by_unit ?? 0) <= 0 ||
      (updatedRow.discount ?? 0) < 0 ||
      (updatedRow.discount ?? 0) > 100 ||
      !(updatedRow.item_description && updatedRow.item_description?.length > 0) ||
      !(updatedRow.item_description && updatedRow.item_description?.length < 1000)
    )) {
      showSnackBar(t("salesinvoicedetails.snackbar.warning"), "warning")
      return oldRow;
    }
    else if ((myLocalInvoiceDocType === 2) && (
      ((updatedRow.quantity ?? 0) <= 0) ||
      (updatedRow.price_by_unit ?? 0) >= 0 ||
      (updatedRow.discount ?? 0) < 0 ||
      (updatedRow.discount ?? 0) > 100 ||
      !(updatedRow.item_description && updatedRow.item_description?.length > 0) ||
      !(updatedRow.item_description && updatedRow.item_description?.length < 1000)
    )) {
      showSnackBar(t("salesinvoicedetails.snackbar.warning"), "warning")
      return oldRow;
    }
    else if (!isCanceledRow) {
      let myQuantity = roundToTwoDecimals(updatedRow?.quantity ?? 0);
      let myData = {
        quantity: myQuantity,
        price_by_unit: updatedRow?.price_by_unit,
        discount: updatedRow.discount,
        subtotal_tax_amount: updatedRow.subtotal_tax_amount,
        subtotal_amount: updatedRow.subtotal_amount,
        total_amount: (updatedRow.quantity ?? 0) * (updatedRow.price_by_unit ?? 0),
        tax_id: updatedRow.tax_id ?? null,
        tax_name: updatedRow.tax_name ?? null,
        tax_percent: updatedRow.tax_percent ?? null,
        tax_code: updatedRow.tax_code ?? null,
        name_item_family: updatedRow.name_item_family,
        name_item_type: updatedRow.name_item_type,
        item_weight: updatedRow.item_weight ?? null,
        item_description: updatedRow.item_description,
        item_code: updatedRow.item_code,
        item_id: updatedRow.item_id,
        unit_id: updatedRow.unit_id,
        unit_code: updatedRow.unit_code,
        unit_description: updatedRow.unit_description,
        unit_symbol: updatedRow.unit_symbol,
        international_unit_symbol: updatedRow.international_unit_symbol,
        tax_object: updatedRow?.tax_object,
        product_service_key: updatedRow?.product_service_key,
        retention_id: updatedRow.retention_id,
        retention_code: updatedRow.retention_code,
        retention_name: updatedRow.retention_name,
        retention_percent: updatedRow.retention_percent,
        tariff_fraction_id: updatedRow.tariff_fraction_id ?? null,
        tariff_fraction_code: updatedRow.tariff_fraction_code ?? null,
        tariff_fraction_general_description: updatedRow.tariff_fraction_general_description ?? null,
        tariff_fraction_specific_description: updatedRow.tariff_fraction_specific_description ?? null,
        tariff_fraction_unit_id: updatedRow.tariff_fraction_unit_id ?? null,
        tariff_fraction_unit_code: updatedRow.tariff_fraction_unit_code ?? null,
        tariff_fraction_unit_symbol: updatedRow.tariff_fraction_unit_symbol ?? null,
        sales_invoice_id: location.state.row.sales_invoice_id,
        sales_shipping_document_detail_id: null
      }
      if (myLocalInvoiceDocType === 2) {
        myData.price_by_unit = (myData.price_by_unit && myData.price_by_unit > 0) ? - myData.price_by_unit : myData.price_by_unit;
        myData.subtotal_tax_amount = (myData.subtotal_tax_amount && myData.subtotal_tax_amount > 0) ? - myData.subtotal_tax_amount : myData.subtotal_tax_amount;
        myData.subtotal_amount = (myData.subtotal_amount && myData.subtotal_amount > 0) ? - myData.subtotal_amount : myData.subtotal_amount;
        myData.total_amount = (myData.total_amount && myData.total_amount > 0) ? - myData.total_amount : myData.total_amount;
      }
      GenericPutResource(`/salesinvoicedetails/${updatedRow.sales_invoice_detail_id}`, myData)
        .then((response) => {
          updatedRow.total_amount = response.data.total_amount;
          setRows((prev) => rows.map((row) => (row.sales_invoice_detail_id === newRow.sales_invoice_detail_id ? updatedRow : row)));
          changeTotalValue();
          showSnackBar(t("salesinvoicedetails.snackbar.update"), "success")
        })
        .catch((error) => {
          showSnackBar(error.message, "error")
        });

      return updatedRow;
    }
    else {
      setIsCanceledRow(false);
      return oldRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    if (salesInvoicePayload !== undefined || location.state.row.sales_invoice_id) {
      GenericGetResource(`/salesinvoicedetails/bysalesinvoiceid/${salesInvoicePayload?.sales_invoice_id ?? location.state.row.sales_invoice_id}`)
        .then((response) => {
          setRows(response.data.data);
          loadColumnsOrder();
          setDataLoaded(true);
        })
        .catch((error) => {
          showSnackBar(error.message, "error")
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    loadColumnsOrder();
  }, [includesForeignTradeComplement, isAccounted]);

  useEffect(() => {

    let updateColumns = [...myColumns]
    const actionsColumnIndex = updateColumns.findIndex(column => column.field === "actions");

    updateColumns[actionsColumnIndex] = {
      field: 'actions',
      type: 'actions',
      headerName: t("generic.grid-table.options"),
      cellClassName: 'actions my-custom-cell',
      getActions: ({ id }: any) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={(resourceSalesInvoiceDetails?.update) ? false : true}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={(resourceSalesInvoiceDetails?.delete) ? false : true}
          />,
        ];
      },
      flex: 1
    }

    setMyColumns(updateColumns)
  }, [rowModesModel])



  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <TableSalesInvoiceDetail
          rows={rows}
          columns={myColumns}
          setColumns={setMyColumns}
          nameOrderColumns={"grid-salesinvoicedetails-columns"}
          rowModesModel={rowModesModel}
          handleRowModesModelChange={handleRowModesModelChange}
          handleRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          setRows={setRows}
          setRowModesModel={setRowModesModel}
          changeTotalValue={changeTotalValue}
          isEdit={isAccounted}
          includesForeignTradeComplement={includesForeignTradeComplement}
          myLocalInvoiceDocType={myLocalInvoiceDocType}
          loadingPut={loadingPut}
          resourceScreenDetail={resourceSalesInvoiceDetails}
        />
      }
      <DialogEntity
        open={openTaxDialog}
        setOpen={setOpenTaxDialog}
        content={
          <>
            <TaxCombo myTax={myTempTax} setMyTax={setMyTempTax} />
            <div className="d-flex flex-row-reverse m-2">
              <PrimaryButton onClick={onAcceptNewTax} >{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => setOpenTaxDialog(false)}>{t("generic.buttons.cancel")}</Button>
            </div>
          </>
        }
        title={<></>}
      />
      <DialogEntity
        open={openRetentionDialog}
        setOpen={setOpenRetentionDialog}
        content={
          <Box width={400}>
            <RetentionCombo myRetention={myTempRetention} setMyRetention={setMyTempRetention} />
            <div className="d-flex flex-row-reverse m-2">
              <PrimaryButton onClick={onAcceptNewRetention}>{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => setOpenRetentionDialog(false)}>{t("generic.buttons.cancel")}</Button>
            </div>
          </Box>
        }
        title={<></>}
      />
      <DialogEntity
        open={openTariffFractionDialog}
        setOpen={setOpenTariffFractionDialog}
        content={
          <Box width={400}>
            <TariffFractionsCombo myTariffFraction={myTempTariffFraction} setMyTariffFraction={setMyTempTariffFraction} />
            <div className="d-flex flex-row-reverse m-2">
              <PrimaryButton onClick={onAcceptNewTariffFraction}>{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => setOpenTariffFractionDialog(false)}>{t("generic.buttons.cancel")}</Button>
            </div>
          </Box>
        }
        title={<></>}
      />
      <GenericDialog
        open={openDeleteDialog}
        onAcept={onDeleteRow}
        setOpen={setOpenDeleteDialog}
        content={t("salesinvoicedetails.dialog.delete")}
        title={t("generic.dialog.delete")}
      />
      <SnackbarComponent />
    </>
  )
}
