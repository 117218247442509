import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { GridActionsCellItem, GridColDef, GridEventListener, GridRenderCellParams, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../api/GenericPromises';
import { useLocation } from 'react-router-dom';
import { UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Autocomplete, Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import { Tax } from '../../../interfaces/Sales/Catalogs/taxes';
import { Retention } from '../../../interfaces/Sales/Catalogs/retentions';
import { PrimaryButton } from '../../../theme/buttons';
import { DialogEntity } from '../../../components/Dialogs/DialogEntity';
import { GenericDialog } from '../../../components/Dialogs/Dialog';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { User } from '../../../interfaces/Security/users';
import { PurchaseInvoice, PurchaseInvoiceDetail } from '../../../interfaces/Purchases/Invoices/purchaseInvoices';
import { usePermissions } from '../../../hooks/usePermissions';
import { Menuitem } from '../../../interfaces/Security/menu';
import TablePurchaseInvoiceDetail from '../../../components/Tables/TablePurchaseInvoiceDetail';

type TaxComboProps = {
  myTax: Tax | undefined,
  setMyTax: React.Dispatch<React.SetStateAction<any>>,
}

const TaxCombo = ({ myTax, setMyTax }: TaxComboProps) => {
  const [comboTaxes, setComboTaxes] = useState<Tax[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [t] = useTranslation("global");

  useEffect(() => {
    GenericGetResource("/taxes")
      .then((response) => {
        setComboTaxes(response.data.data);
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    setMyTax(value);
  }

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Autocomplete
          value={myTax || null}
          size="small"
          sx={{ flex: 1, paddingRight: 1 }}
          options={comboTaxes}
          getOptionLabel={(option) => `${option.tax_name} - ${option.tax_percent}`}
          defaultValue={undefined}
          renderOption={(props, option: Tax) => (
            <div key={option.tax_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.tax_name} - {option.tax_percent}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.tax_id === value.tax_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("taxes.title-view")}`}
              variant="filled"
            />
          )}
        />
      }
    </>
  );
}

type RetentionComboProps = {
  myRetention: Retention | undefined,
  setMyRetention: React.Dispatch<React.SetStateAction<any>>,
}

const RetentionCombo = ({ myRetention, setMyRetention }: RetentionComboProps) => {
  const [comboRetentions, setComboRetentions] = useState<Retention[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GenericGetResource } = GenericPromises();
  const [t] = useTranslation("global");

  useEffect(() => {
    GenericGetResource("/retentions")
      .then((response) => {
        setComboRetentions(response.data.data);
        setDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    setMyRetention(value);
  }

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Autocomplete
          value={myRetention || null}
          size="small"
          sx={{ flex: 1, paddingRight: 1 }}
          options={comboRetentions}
          getOptionLabel={(option) => `${option.retention_name} - ${option.retention_percent}`}
          defaultValue={undefined}
          renderOption={(props, option: Retention) => (
            <div key={option.retention_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.retention_name} - {option.retention_percent}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.retention_id === value.retention_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("retentions.title-view")}`}
              variant="filled"
            />
          )}
        />
      }
    </>
  );
}

type InvoiceBodyProps = {
  purchaseInvoicePayload?: PurchaseInvoice | undefined;
  setValue: UseFormSetValue<PurchaseInvoice>;
  getValues: UseFormGetValues<PurchaseInvoice>;
  setIsChangedTotalValue: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean,
  reset: UseFormReset<PurchaseInvoice>,
  onPut: (data: PurchaseInvoice) => void,
  myLocalInvoiceDocType: number,
  rows: PurchaseInvoiceDetail[],
  loadingPut: boolean,
  setRows: Dispatch<SetStateAction<PurchaseInvoiceDetail[]>>
}

export const InvoiceBody = ({
  purchaseInvoicePayload,
  setValue,
  setIsChangedTotalValue,
  isEdit,
  myLocalInvoiceDocType,
  rows,
  loadingPut,
  setRows
}: InvoiceBodyProps) => {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [t] = useTranslation("global");
  const { GenericGetResource, GenericPutResource, GenericDeleteResource } = GenericPromises();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openTaxDialog, setOpenTaxDialog] = useState(false);
  const [openRetentionDialog, setOpenRetentionDialog] = useState(false);
  const [isCanceledRow, setIsCanceledRow] = useState(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId>(0);
  const [mySelectedRow, setMySelectedRow] = useState<GridRowModel>();
  const [myTempTax, setMyTempTax] = useState<Tax>();
  const [myTempRetention, setMyTempRetention] = useState<Retention>();
  const { GetResourceByUrl } = usePermissions();
  const [resourceScreenDetails, setResourceScreenDetails] = useState<Menuitem>();
  const [isLoadedResource, setIsLoadedResource] = useState(false);
  const location = useLocation();


  let columns: GridColDef[] = (
    (!isEdit) ? [
      {
        field: 'item_code',
        headerName: t("items.fields.item_code"),
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'item_description',
        headerName: t("salesinvoicedetails.fields.concept"),
        flex: 1,
        editable: true,
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <Tooltip title={`${params.row.item_description}`}>
            {params.row.item_description}
          </Tooltip>
        ),
        cellClassName: "my-custom-cell"
      },
      {
        field: 'quantity',
        headerName: t("salesinvoicedetails.fields.quantity"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        flex: 1,
        valueGetter: (value: any) => {
          return roundToTwoDecimals(value.value)
        },
        cellClassName: "my-custom-cell"
      },
      {
        field: 'price_by_unit',
        headerName: t("purchaseinvoicesdetails.fields.price"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'unit_code',
        headerName: t("purchaseinvoicesdetails.fields.unit_code"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'tax_id',
        headerName: t("taxes.title-view"),
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <div>
            {resourceScreenDetails?.update &&
              <IconButton
                onClick={() => {
                  let myTax: Tax = {
                    tax_id: params.row.tax_id,
                    tax_code: params.row.tax_code,
                    tax_name: params.row.tax_name,
                    tax_percent: params.row.tax_percent,
                  }
                  setMyTempTax(myTax.tax_percent ? myTax : undefined);
                  setMySelectedRow(params);
                  setOpenTaxDialog(true);
                }}
                size="small">
                <EditIcon />
              </IconButton>}
            %{params.row.tax_percent} - {params.row.tax_name}
          </div>
        ),
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'retention_id',
        headerName: t("retentions.title-view"),
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <div>
            {resourceScreenDetails?.update &&
              <IconButton
                onClick={() => {
                  let myRetention: Retention = {
                    retention_id: params.row.retention_id,
                    retention_code: params.row.retention_code,
                    retention_name: params.row.retention_name,
                    retention_percent: params.row.retention_percent,
                  }
                  setMyTempRetention(myRetention.retention_percent ? myRetention : undefined);
                  setMySelectedRow(params);
                  setOpenRetentionDialog(true);
                }}
                size="small">
                <EditIcon />
              </IconButton>
            }
            {params.row.retention_percent}% - {params.row.retention_name}
          </div>
        ),
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'discount',
        headerName: t("purchaseinvoicesdetails.fields.discount"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        editable: true,
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'total_amount',
        headerName: t("purchaseinvoicesdetails.fields.subtotal_amount"),
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        cellClassName: "my-custom-cell"
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: t("generic.grid-table.options"),
        cellClassName: 'actions my-custom-cell',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              disabled={!(resourceScreenDetails?.update ?? false)}
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              disabled={!(resourceScreenDetails?.delete ?? false)}
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
        flex: 1
      },
    ] :
      [
        {
          field: 'item_code',
          headerName: t("items.fields.item_code"),
          flex: 1,
          cellClassName: "my-custom-cell"
        },
        {
          field: 'item_description',
          headerName: t("purchaseinvoicesdetails.fields.concept"),
          flex: 1,
          cellClassName: "my-custom-cell"
        },
        {
          field: 'quantity',
          headerName: t("purchaseinvoicesdetails.fields.quantity"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: "my-custom-cell"
        },
        {
          field: 'price_by_unit',
          headerName: t("purchaseinvoicesdetails.fields.price"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: "my-custom-cell"
        },
        {
          field: 'unit_code',
          headerName: t("purchaseinvoicesdetails.fields.unit_code"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: "my-custom-cell"
        },
        {
          field: 'tax_id',
          headerName: t("taxes.title-view"),
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              {params.row.tax_name} - {params.row.tax_percent}%
            </div>
          ),
          flex: 1,
          cellClassName: "my-custom-cell"
        },
        {
          field: 'retention_id',
          headerName: t("retentions.title-view"),
          renderCell: (params: GridRenderCellParams<any, Date>) => (
            <div>
              {params.row.retention_name} - {params.row.retention_percent}
            </div>
          ),
          flex: 1,
          cellClassName: "my-custom-cell"
        },
        {
          field: 'discount',
          headerName: t("purchaseinvoicesdetails.fields.discount"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: "my-custom-cell"
        },
        {
          field: 'total_amount',
          headerName: t("purchaseinvoicesdetails.fields.subtotal_amount"),
          type: 'number',
          headerAlign: 'left',
          align: 'left',
          flex: 1,
          cellClassName: "my-custom-cell"
        },
      ]
  );


  const [myColumns, setMyColumns] = useState<GridColDef[]>(columns);

  const reorderColumns = (originalColumns: GridColDef[], newOrder: string[]) => {
    const orderedColumns: GridColDef[] = [];
    const actionsColumn = originalColumns.find(col => col.field === 'actions');
    const columnsToAdd = originalColumns.filter(col => !newOrder.includes(col.field));

    columnsToAdd.forEach(col => {
      if (col.field !== 'actions') {
        orderedColumns.push(col);
      }
    });

    newOrder.forEach((fieldName) => {
      const column = originalColumns.find((col) => col.field === fieldName);
      if (column && column.field !== 'actions') {
        orderedColumns.push(column);
      }
    });

    if (actionsColumn) {
      orderedColumns.push(actionsColumn);
    }

    return orderedColumns;
  };

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-purchaseinvoicesdetails-columns");
    if (myOrderColumns !== null) {
      let myJson: any[] = JSON.parse(myOrderColumns);

      myJson.forEach((columnConfig) => {
        if (columnConfig.type === 'date') {
          columnConfig.headerName = t(`generic.${columnConfig.field}`);
          columnConfig.valueGetter = function (params: GridValueGetterParams<User, any, GridTreeNodeWithRender>) {
            return new Date(params.value);
          };
        } else {
          columnConfig.headerName = t(`purchaseinvoicesdetails.fields.${columnConfig.field}`);
        }
      });

      const reorderedColumns = reorderColumns(columns, myJson.map((col) => col.field));

      setMyColumns((prev) => reorderedColumns);
    } else {
      setMyColumns(columns)
    }
  };


  const onAcceptNewTax = () => {
    let myRow = mySelectedRow?.row;
    myRow.tax_id = myTempTax?.tax_id ?? null;
    myRow.tax_name = myTempTax?.tax_name ?? null;
    myRow.tax_percent = myTempTax?.tax_percent ?? null;
    myRow.tax_code = myTempTax?.tax_code ?? null;
    processRowUpdate(myRow, myRow);
    setOpenTaxDialog(false);
  }

  const onAcceptNewRetention = () => {
    let myRow = mySelectedRow?.row;
    myRow.retention_id = myTempRetention?.retention_id ?? null;
    myRow.retention_code = myTempRetention?.retention_code ?? null;
    myRow.retention_name = myTempRetention?.retention_name ?? null;
    myRow.retention_percent = myTempRetention?.retention_percent ?? null;
    processRowUpdate(myRow, myRow);
    setOpenRetentionDialog(false);
  }

  const changeTotalValue = () => {
    GenericGetResource(`/purchaseinvoices/${purchaseInvoicePayload?.purchase_invoice_id}`)
      .then((response) => {
        setValue("tax_amount", response.data.tax_amount);
        setValue("retention_amount", response.data.retention_amount);
        setValue("subtotal_amount", response.data.subtotal_amount);
        setValue("discount_amount", response.data.discount_amount);
        setValue("total_amount", response.data.total_amount);
        setIsChangedTotalValue((prev) => !prev);
      })
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (purchase_invoice_detail_id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [purchase_invoice_detail_id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (purchase_invoice_detail_id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [purchase_invoice_detail_id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (purchase_invoice_detail_id: GridRowId) => () => {
    setIdToDelete((prev) => purchase_invoice_detail_id);
    setOpenDeleteDialog(true);
  };

  function roundToTwoDecimals(num: number): number {
    return Math.round(num * 100) / 100;
  }

  const onDeleteRow = () => {
    GenericDeleteResource(`/purchaseinvoicesdetails/${idToDelete}`)
      .then(() => {
        setRows(rows.filter((row) => row.purchase_invoice_detail_id !== idToDelete));
        changeTotalValue();
        setOpenDeleteDialog(false);
        showSnackBar(t("purchaseinvoicesdetails.snackbar.delete"), "success")
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }

  const handleCancelClick = (purchase_invoice_detail_id: GridRowId) => () => {
    setIsCanceledRow(true);
    setRowModesModel({ ...rowModesModel, [purchase_invoice_detail_id]: { mode: GridRowModes.View } });
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const updatedRow: PurchaseInvoiceDetail = { ...newRow };
    if ((myLocalInvoiceDocType !== 2) && (
      ((updatedRow.quantity ?? 0) <= 0) ||
      (updatedRow.price_by_unit ?? 0) <= 0 ||
      (updatedRow.discount ?? 0) < 0 ||
      (updatedRow.discount ?? 0) > 100 ||
      !(updatedRow.item_description && updatedRow.item_description?.length > 0) ||
      !(updatedRow.item_description && updatedRow.item_description?.length < 1000)
    )) {
      showSnackBar(t("purchaseinvoicesdetails.snackbar.warning"), "warning")
      return oldRow;
    }
    else if ((myLocalInvoiceDocType === 2) && (
      ((updatedRow.quantity ?? 0) <= 0) ||
      (updatedRow.price_by_unit ?? 0) >= 0 ||
      (updatedRow.discount ?? 0) < 0 ||
      (updatedRow.discount ?? 0) > 100 ||
      !(updatedRow.item_description && updatedRow.item_description?.length > 0) ||
      !(updatedRow.item_description && updatedRow.item_description?.length < 1000)
    )) {
      showSnackBar(t("purchaseinvoicesdetails.snackbar.warning"), "warning")
      return oldRow;
    }
    else if (!isCanceledRow) {
      let myData = {
        quantity: updatedRow?.quantity,
        price_by_unit: updatedRow?.price_by_unit,
        discount: updatedRow.discount,
        subtotal_tax_amount: updatedRow.subtotal_tax_amount,
        subtotal_amount: updatedRow.subtotal_amount,
        total_amount: (updatedRow.quantity ?? 0) * (updatedRow.price_by_unit ?? 0),
        tax_id: updatedRow.tax_id ?? null,
        tax_name: updatedRow.tax_name ?? null,
        tax_percent: updatedRow.tax_percent ?? null,
        tax_code: updatedRow.tax_code ?? null,
        name_item_family: updatedRow.name_item_family,
        name_item_type: updatedRow.name_item_type,
        item_weight: updatedRow.item_weight ?? null,
        item_description: updatedRow.item_description,
        item_code: updatedRow.item_code,
        item_id: updatedRow.item_id,
        unit_id: updatedRow.unit_id,
        unit_code: updatedRow.unit_code,
        unit_description: updatedRow.unit_description,
        unit_symbol: updatedRow.unit_symbol,
        international_unit_symbol: updatedRow.international_unit_symbol,
        tax_object: updatedRow?.tax_object,
        product_service_key: updatedRow?.product_service_key,
        retention_id: updatedRow.retention_id,
        retention_code: updatedRow.retention_code,
        retention_name: updatedRow.retention_name,
        retention_percent: updatedRow.retention_percent,
        purchase_invoice_id: location.state.row.purchase_invoice_id,
      }
      GenericPutResource(`/purchaseinvoicesdetails/${updatedRow.purchase_invoice_detail_id}`, myData)
        .then((response) => {
          updatedRow.total_amount = response.data.total_amount;
          setRows((prev) => rows.map((row) => (row.purchase_invoice_detail_id === newRow.purchase_invoice_detail_id ? updatedRow : row)));
          changeTotalValue();
          showSnackBar(t("purchaseinvoicesdetails.snackbar.update"), "success")
        })
        .catch((error) => {
          showSnackBar(error.message, "error")
        });

      return updatedRow;
    }
    else {
      setIsCanceledRow(false);
      return oldRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    if (purchaseInvoicePayload !== undefined || location.state.row.purchase_invoice_id) {
      GetResourceByUrl("/purchaseinvoicesdetails").then((responsePermissions) => {
        setResourceScreenDetails(responsePermissions);
        setIsLoadedResource(true);
      }).catch((error) => {
        setDataLoaded(true);
        setIsLoadedResource(true);
        showSnackBar(error.message, "error")
      });
    }
  }, [])

  useEffect(() => {
    if (resourceScreenDetails?.read) {
      GenericGetResource(`/purchaseinvoicesdetails/bypurchaseinvoiceid/${purchaseInvoicePayload?.purchase_invoice_id ?? location.state.row.purchase_invoice_id}`)
        .then((response) => {
          setRows(response.data.data);
          loadColumnsOrder();
          setDataLoaded(true);
        })
        .catch((error) => {
          setDataLoaded(true);
          showSnackBar(error.message, "error")
        });
    } else {
      setDataLoaded(isLoadedResource)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedResource]);


  useEffect(() => {
    loadColumnsOrder();
  }, [isEdit]);

  useEffect(() => {
    let updateColumns = [...myColumns]
    const actionsColumnIndex = updateColumns.findIndex(column => column.field === "actions");

    updateColumns[actionsColumnIndex] = {
      field: 'actions',
      type: 'actions',
      headerName: t("generic.grid-table.options"),
      cellClassName: 'actions my-custom-cell',
      getActions: ({ id }: any) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            disabled={!(resourceScreenDetails?.update ?? false)}
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            disabled={!(resourceScreenDetails?.delete ?? false)}
            color="inherit"
          />,
        ];
      },
      flex: 1
    }

    setMyColumns(updateColumns)
  }, [rowModesModel]);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <TablePurchaseInvoiceDetail
          rows={rows}
          columns={myColumns}
          setColumns={setMyColumns}
          nameOrderColumns={"grid-purchaseinvoicesdetails-columns"}
          rowModesModel={rowModesModel}
          handleRowModesModelChange={handleRowModesModelChange}
          handleRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          setRows={setRows}
          setRowModesModel={setRowModesModel}
          changeTotalValue={changeTotalValue}
          isEdit={(isEdit) ? false : resourceScreenDetails?.create ?? false}
          myLocalInvoiceDocType={myLocalInvoiceDocType}
          loadingPut={loadingPut}
        />
      }
      <DialogEntity
        open={openTaxDialog}
        setOpen={setOpenTaxDialog}
        content={
          <>
            <TaxCombo myTax={myTempTax} setMyTax={setMyTempTax} />
            <div className="d-flex flex-row-reverse m-2">
              <PrimaryButton onClick={onAcceptNewTax} >{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => setOpenTaxDialog(false)}>{t("generic.buttons.cancel")}</Button>
            </div>
          </>
        }
        title={<></>}
      />
      <DialogEntity
        open={openRetentionDialog}
        setOpen={setOpenRetentionDialog}
        content={
          <Box width={400}>
            <RetentionCombo myRetention={myTempRetention} setMyRetention={setMyTempRetention} />
            <div className="d-flex flex-row-reverse m-2">
              <PrimaryButton onClick={onAcceptNewRetention}>{t("generic.buttons.accept")}</PrimaryButton>
              <Button variant='outlined' onClick={() => setOpenRetentionDialog(false)}>{t("generic.buttons.cancel")}</Button>
            </div>
          </Box>
        }
        title={<></>}
      />
      <GenericDialog
        open={openDeleteDialog}
        onAcept={onDeleteRow}
        setOpen={setOpenDeleteDialog}
        content={t("purchaseinvoicesdetails.dialog.delete")}
        title={t("generic.dialog.delete")}
      />
      <SnackbarComponent />
    </>
  )
}
