import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next";

type DialogProps = {
    open: boolean,
    setOpen?: (open: boolean) => void,
    title: JSX.Element,
    content: JSX.Element,
    fullWidth?: boolean,
    maxWidth?: Breakpoint,
    disableEscapeKeyDown?: boolean,
    disabledBackdropClick?: boolean
}

export const DialogEntity = ({ open, setOpen, title, content, fullWidth = false, disableEscapeKeyDown = false, maxWidth = "xl", disabledBackdropClick = false }: DialogProps) => {
    const [t] = useTranslation("global");
    return (
        <Dialog
            open={open}
            onClose={(_,reason) => {
                if (!(disabledBackdropClick && reason == "backdropClick")) {
                    setOpen && setOpen(false);
                }
            }}
            aria-labelledby="responsive-dialog-title"
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            disableEscapeKeyDown={disableEscapeKeyDown}
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
        </Dialog>
    )
}
